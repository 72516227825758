import React from "react"
import Layout from "../components/layout"
import Title from "../components/title"
import { graphql } from 'gatsby'
import Article from "../components/article"

const Articles = ({data}) => {
  return (
    <Layout>
      <Title text='Articles'></Title>
      
      {data.allMarkdownRemark.edges.map(({node}) => (
          <Article 
            id={node.id}
            to={node.fields.slug}
            title={node.frontmatter.title}
            keyword={node.frontmatter.keywords}
            excerpt={node.excerpt}
            date={node.frontmatter.date} />
      ))}
    </Layout>
    )
}

export const query = graphql`
  query {
      allMarkdownRemark(sort: {fields: [frontmatter___date], order: DESC}) {
          totalCount
          edges {
            node {
              fields {
                slug
              }
              frontmatter {
                date(formatString: "MMM YYYY")
                title
                image
                keywords
              }
              excerpt
            }
          }      
        }
  }`


export default Articles
