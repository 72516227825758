import React from 'react'
import * as styles from './header.module.scss'
import { Link } from 'gatsby'
import { graphql, StaticQuery } from 'gatsby'

// HeaderLink component
const HeaderLink = props => (
    <Link className={styles.link} to={props.to}>{props.text}</Link>
  )
  
  // HomeButton component
  const HomeButton = props => (
    <Link to={props.to}>
      <div className={styles.button}>{props.text}</div>
    </Link>
  )
  
  // SocialButton component
  const SocialButton = (props) => {
  
    let style = '';
    let url = '';
  
    if (props.site === 'twitter') {
      style = styles.buttonTwitter;
      url = "https://twitter.com/" + props.username;
    }
    else if (props.site === 'linkedin') {
      style = styles.buttonLinkedin;
      url = "https://www.linkedin.com/in/" + props.username;
    }
    else if (props.site === 'github') {
      style = styles.buttonGithub;
      url = "https://www.github.com/" + props.username;
    }
    
    return (
      <a href={url} target="_blank" rel="noopener noreferrer">
        <div className={style}>{props.children}&nbsp;</div>
      </a>
    )
  
  }
  
  const header = () => (
    <StaticQuery 
      query= {graphql `
        query {
          site {
            siteMetadata {
              title
              subtitle
            }
          }
        }`
      }
      render = { data => (
          <header className={styles.container}>
            <div className={styles.row}>
              {/*<HomeButton to='/' text={data.site.siteMetadata.title} />
              <SocialButton site="github" username="evangeloper"></SocialButton>
              <SocialButton site="linkedin" username="evangeloper"></SocialButton>
      <SocialButton site="twitter" username="evangeloper"></SocialButton>*/}
            </div>
            <div className= {styles.header}>HALTON UNITED</div>          

            <div className={styles.row}>
              <HeaderLink to='/' text='HOME' />
              <HeaderLink to='/articles' text='ARTICLES' />
              <HeaderLink to='/EmailTemplates' text='EMAIL TEMPLATES'/>
              <HeaderLink to='/about' text='ABOUT'/>
            </div>
          </header>
        )
      }
    />
  )

export default header