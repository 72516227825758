import React from 'react'
import * as styles from './layout.module.scss'
import Header from "../components/header"
import Footer from "../components/footer"

export const layout = ({children}) => {
  return (
    <div className={styles.container}>
    <Header />
        <div className={styles.content}>
            {children}
        </div>
    <Footer>
        HALTON UNITED 2022
    </Footer>


    </div>
  )
}

export default layout